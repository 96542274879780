<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">New Timeline</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-8">
                  <div class="row mb-2">
                    <label for="level" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <v-select
                        :disabled="
                          currentRouteName == 'program-timeline-update'
                        "
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        :selectable="(options) => isSelectable(options)"
                        label="name"
                        :options="programs"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="short_name" class="col-md-3 form-label">
                      Program Code
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        id="short_name"
                        name="short_name"
                        class="form-control"
                        placeholder="Program Short Name"
                        v-model="timeline.program.code"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="row col-md-4">
                  <div
                    class="row mb-2"
                    v-if="currentRouteName === 'program-timeline-update'"
                  >
                    <label for="status" class="col-md-4 form-label">
                      Status
                      <!-- <span class="text-danger">*</span> -->
                      <!-- :disabled="
                        currentRouteName == 'trainer-update' ? false : true
                      " -->
                    </label>
                    <div class="col-md-7">
                      <select
                        name="status"
                        v-model="status"
                        class="form-control"
                      >
                        <option :value="1">Active</option>
                        <option :value="0">Inactive</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mb-4" v-if="status == 0">
                    <label for="remark" class="col-md-4 form-label">
                      Remark
                      <!-- <span class="text-danger">*</span> -->
                    </label>
                    <div class="col-md-7">
                      <textarea
                        type="text"
                        id="remark"
                        name="remark"
                        class="form-control"
                        placeholder="Remark"
                        v-model="remark"
                        :class="{
                          'p-invalid': v$.remark.$error || errorFor('remark'),
                        }"
                      >
                      </textarea>
                      <v-errors
                        :serverErrors="errorFor('remark')"
                        :vuelidateErrors="{
                          errors: v$.remark.$errors,
                          value: 'Remark',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>
              </div>

              <div class="float-end">
                <button
                  v-show="!loading"
                  type="button"
                  @click="addTimeline()"
                  class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                >
                  <i class="mdi mdi-plus-circle"></i> Add
                </button>
              </div>

              <div
                class="progress mt-5"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row mt-2 text-center">
                <div class="d-flex">
                  <div class="col-md-3">
                    <label for="name" class="form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-1">
                    <label for="name" class="form-label">
                      Type
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label for="name" class="form-label">
                      Month
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-1">
                    <label for="name" class="form-label">
                      Week
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-3">
                    <label for="name" class="form-label">
                      Day
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div
                  v-for="(item, index) in timeline.program_timeline"
                  :key="index"
                  class="d-flex m-2"
                >
                  <div class="col-md-2 me-2">
                    <v-select
                      v-model="item.level"
                      label="name"
                      :options="levels"
                      :reduce="(name) => name.name"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-2 me-2">
                    <v-select
                      v-model="item.type"
                      label="name"
                      :options="types"
                      :reduce="(name) => name.name"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-2 me-2">
                    <input
                      type="number"
                      id="month"
                      name="month"
                      class="form-control"
                      oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                      placeholder="Month"
                      v-model="item.month"
                    />
                  </div>
                  <div class="col-md-2 me-2">
                    <input
                      type="number"
                      id="week"
                      name="week"
                      class="form-control"
                      oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                      placeholder="Week"
                      v-model="item.week"
                    />
                  </div>
                  <div class="col-md-2 me-2">
                    <input
                      type="number"
                      id="day"
                      name="day"
                      class="form-control"
                      oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                      placeholder="Day"
                      v-model="item.day"
                    />
                  </div>
                  <div class="col-md-2" v-show="index != 0">
                    <div v-show="!loading" @click="removeTimeline(index)">
                      <i class="fas fa-trash-alt mx-1"></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'program-timeline' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      v-if="currentRouteName === 'program-timeline-update'"
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="updateTimeline()"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="createTimeline()"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors],
  data() {
    return {
      timelineList: [],
      programs: [],
      levels: [],
      types: [
        {
          id: 1,
          name: "Orientation",
        },
        {
          id: 2,
          name: "InClass",
        },
        {
          id: 3,
          name: "OJT",
        },
      ],
      timeline: {
        program: {
          id: "",
          name: "",
          code: "",
        },
        program_timeline: [
          {
            level: "",
            type: "",
            month: "",
            week: "",
            day: "",
          },
        ],
      },
      selectedProgram: null,
      status: "1",
      remark: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      timeline: {
        program: {
          id: { required },
          name: { required },
          code: { required },
        },
      },
      remark: {
        required: requiredIf(() => {
          return this.status != 1;
        }),
      },
    };
  },
  methods: {
    addTimeline() {
      this.timeline.program_timeline.push({
        level: "",
        type: "",
        month: "",
        week: "",
        day: "",
      });
    },
    removeTimeline(index) {
      this.timeline.program_timeline.splice(index, 1);
    },
    getSelectedProgram() {
      if (this.selectedProgram) {
        this.timeline.program.id = this.selectedProgram.id;
        this.timeline.program.name = this.selectedProgram.name;
        this.timeline.program.code = this.selectedProgram.short_name;
      }
    },
    async getPrograms() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getAllLevels() {
      this.loading = true;
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
      });
    },
    async getTimelineById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/program-timeline/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
          this.timeline = result;
          this.selectedProgram = {
            id: result.program.id,
            name: result.program.name,
            short_name: result.program.code,
          };
          this.timeline.program_timeline = result.program_timeline_details.map(
            (item) => {
              return {
                level: item.level,
                type: item.type,
                month: item.month,
                week: item.week,
                day: item.day,
              };
            }
          );
          this.status = result.status;
          this.remark = result.remark;
        })
        .catch(() => {
          this.toast.error("Not Found Data!");
        });
      this.loading = false;
    },

    checkEmptyValue(array) {
      let timelineData = Object.values(array);
      if (timelineData.includes(null) || timelineData.includes("")) {
        return false;
      } else {
        return true;
      }
    },

    async createTimeline() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let checkTimeline = [];
      this.timeline.program_timeline.forEach((array) => {
        let timeline_data = this.checkEmptyValue(array);
        checkTimeline.push(timeline_data);
      });
      if (checkTimeline.includes(false)) {
        this.toast.error("Please Fill All Input!");
        return;
      }

      this.isLoading = true;
      let data = {
        program_id: this.timeline.program.id,
        program_name: this.timeline.program.name,
        program_short_name: this.timeline.program.code,
        program_timeline_details: this.timeline.program_timeline,
      };

      await axios
        .post(`${this.baseUrl}admin/v2/program-timeline`, data)
        .then(() => {
          this.$router.push({ name: "program-timeline" });
          this.toast.success("Successfully Created Timeline!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateTimeline() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      let checkTimeline = [];
      this.timeline.program_timeline.forEach((array) => {
        let timeline_data = this.checkEmptyValue(array);
        checkTimeline.push(timeline_data);
      });
      if (checkTimeline.includes(false)) {
        this.toast.error("Please Fill All Input!");
        return;
      }
      let data = {
        program_id: this.timeline.program.id,
        program_name: this.timeline.program.name,
        program_short_name: this.timeline.program.code,
        program_timeline_details: this.timeline.program_timeline,
        status: this.status,
        remark: this.status == 0 ? this.remark : "",
      };
      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v2/program-timeline/${this.$route.params.id}`,
          data
        )
        .then(() => {
          this.$router.push({ name: "program-timeline" });
          this.toast.success("Successfully Timeline Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async getAllProgramTimeline() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v2/program-timeline`)
        .then((response) => {
          this.timelineList = response.data.data;
          console.log(this.timelineList, 'time list')
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },

    isSelectable(options) {
            if (this.selectedProgram) {
                // Check if the program with the same id exists in timelineList
                const programExists = this.timelineList.some(
                    (item) => item.program_id === this.selectedProgram.id
                );
          
                // If the program doesn't exist, add it to timelineList
                if (!programExists) {
                    this.timelineList = this.timelineList.filter(
                        (item) => !item.is_selected
                    );
                    this.timelineList.push({
                        program_id: this.selectedProgram.id,
                        program_name: this.selectedProgram.name,
                        is_selected: true,
                    });
                }
            }
            // Now, check if the options.id exists in timelineList
            return !this.timelineList.some(
                (item) => item.program_id === options.id
            );
        },
  },
  async created() {
    await this.getPrograms();
    await this.getAllProgramTimeline();
    this.getAllLevels();
    if (this.currentRouteName == "program-timeline-update") {
      this.getTimelineById();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
